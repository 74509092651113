import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// const carouselItems = [
//     { id: 1, image: 'http://localhost:3001/uploads/1722943238245.jpg', title: 'Slide 1', description: 'Description for Slide 1' },
//     { id: 2, image: 'http://localhost:3001/uploads/1722943238245.jpg', title: 'Slide 2', description: 'Description for Slide 2' },
//     { id: 3, image: 'http://localhost:3001/uploads/1722943238245.jpg', title: 'Slide 3', description: 'Description for Slide 3' }
// ];

const Banner = () => {

    const [homeSectionOne, setHomeSectionOne] = useState([]);

    useEffect(() => {
        const fetchHomeSectionOne = async () => {
            try {
                // const result = await fetch('http://localhost:3001/api/fetch-home-section-one');
                const result = await fetch('https://alshifamedicalsupplies.ae/api/fetch-home-section-one');
                if (result.ok) {
                    const data = await result.json();
                    if (data && data.data && data.data.length > 0) {
                        const formattedData = data.data.map(item => ({
                            ...item,
                            bgImage: item.bgImage ? item.bgImage.replace(/\\/g, '/') : null,
                            imageOne: item.imageOne ? item.imageOne.replace(/\\/g, '/') : null,
                            imageTwo: item.imageTwo ? item.imageTwo.replace(/\\/g, '/') : null,
                            imageThree: item.imageThree ? item.imageThree.replace(/\\/g, '/') : null
                        }));
                        setHomeSectionOne(formattedData);
                        // console.log("Banner rendered", formattedData);
                    } else {
                        console.error('Failed to fetch homeSectionOne: No valid data returned');
                    }
                } else {
                    console.error('Unexpected response structure:', result);
                }
            } catch (error) {
                console.error('Failed to fetch homeSectionOne', error);
            }
        };

        fetchHomeSectionOne();
        window.scrollTo(0, 0);

        const sidebar = document.getElementById('sidebar');
        if (sidebar) {
            sidebar.classList.add('hide-sidebar');
        }

    }, []);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    return (
        <div className="bannercontent" style={{ padding: '20px' }}>
            <div style={{ width: '100%', margin: 'auto', overflow: 'hidden' }}>
                <Slider {...settings} style={{ height: '500px' }}>
                    {homeSectionOne.map(item => (
                        <div key={item.id}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="500"
                                    // image={`http://localhost:3001/${item.bgImage}`}
                                    image={`https://alshifamedicalsupplies.ae/${item.bgImage}`}
                                    alt={item.title}
                                />
                                {/* <CardContent>
                                    <Typography variant="h5" component="div">
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {item.description}
                                    </Typography>
                                </CardContent> */}
                            </Card>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>

    );
}

export default Banner;
