import React, { useEffect } from 'react';

const Header = () => {

    // const [homeSectionOne, setHomeSectionOne] = useState([]);

    useEffect(() => {
        // const fetchHomeSectionOne = async () => {
        //     try {
        //         // const result = await fetch('http://localhost:3001/api/fetch-home-section-one');
        //         const result = await fetch('https://alshifamedicalsupplies.ae/api/fetch-home-section-one');
        //         if (result.ok) {
        //             const data = await result.json();
        //             if (data && data.data && data.data.length > 0) {
        //                 const formattedData = data.data.map(item => ({
        //                     ...item,
        //                     bgImage: item.bgImage ? item.bgImage.replace(/\\/g, '/') : null,
        //                     imageOne: item.imageOne ? item.imageOne.replace(/\\/g, '/') : null,
        //                     imageTwo: item.imageTwo ? item.imageTwo.replace(/\\/g, '/') : null,
        //                     imageThree: item.imageThree ? item.imageThree.replace(/\\/g, '/') : null
        //                 }));
        //                 setHomeSectionOne(formattedData);
        //                 // console.log("Banner rendered", formattedData);
        //             } else {
        //                 console.error('Failed to fetch homeSectionOne: No valid data returned');
        //             }
        //         } else {
        //             console.error('Unexpected response structure:', result);
        //         }
        //     } catch (error) {
        //         console.error('Failed to fetch homeSectionOne', error);
        //     }
        // };

        // fetchHomeSectionOne();
        window.scrollTo(0, 0);

        const sidebar = document.getElementById('sidebar');
        if (sidebar) {
            sidebar.classList.add('hide-sidebar');
        }
    }, []);



    return (
        <div className="about-header-content">

            <div class="section_3" style={{ marginTop: '20px' }}>
                <div class="gradient_overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            {/* <div class="sub_title_section">
                                <ul class="sub_title">
                                    <li> <a href="#"> Welcome to </a> <i class="fa fa-angle-right" aria-hidden="true"></i> </li>
                                    <li>  AL Shifa Medical Supplies </li>
                                </ul>
                            </div> */}
                            <div class="page_header_title">
                                <h1>About Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Header;
