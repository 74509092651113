import React, { useEffect } from 'react';

const SectionOne = () => {


    // const [contactSectionOne, setContactSectionOne] = useState([]);

    useEffect(() => {
        // const fetchContactSectionOne = async () => {
        //     try {
        //         // const result = await fetch('http://localhost:3001/api/fetch-about-section-one');
        //         const result = await fetch('https://alshifamedicalsupplies.ae/api/fetch-contact-section-one');
        //         if (result.ok) {
        //             const data = await result.json();
        //             if (data && data.data && data.data.length > 0) {
        //                 setContactSectionOne(data.data[0]);
        //                 // console.log("setContactSectionOne", data);
        //             } else {
        //                 console.error('Failed to fetch contactSectionOne: No valid data returned');
        //             }
        //         } else {
        //             console.error('Unexpected response structure:', result);
        //         }
        //     } catch (error) {
        //         console.error('Failed to fetch aboutSectionOne', error);
        //     }
        // };

        // fetchContactSectionOne();
        window.scrollTo(0, 0);

        const sidebar = document.getElementById('sidebar');
        if (sidebar) {
            sidebar.classList.add('hide-sidebar');
        }
    }, []);



    return (
        <div className="contact-section-one-content">

            {/* <div class="section_1"> */}

            <div class="contact_info_section">
                <div class="contact_info_overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="contact_details_wrapper" style={{ backgroundImage: 'none' }}>
                                <h2>Contact Details</h2>
                                <div class="contact_details_content">

                                    <div class="contact_heading">
                                        <span>Office</span>
                                    </div>
                                    <div class="contact_details">
                                        <span>Al Ain Tower Office No. 404 Hamdan Street</span>
                                    </div>
                                    <div class="contact_heading">
                                        <span>Address:</span>
                                    </div>
                                    <div class="contact_details">
                                        <span>Abu Dhabi UAE</span>
                                    </div>
                                </div>
                                {/* <div class="contact_details_content">
                                    <div class="contact_heading">
                                        <span>Branch</span>
                                    </div>
                                    <div class="contact_details">
                                        <span>Webstrot Web Company, 162-A Kalani Bag</span>
                                    </div>
                                    <div class="contact_heading">
                                        <span>Office:</span>
                                    </div>
                                    <div class="contact_details">
                                        <span>Dewas, M.P.  India 455001</span>
                                    </div>
                                </div> */}
                                <div class="contact_details_content">
                                    <div class="contact_heading">
                                        <span>Phone:</span>
                                    </div>
                                    <div class="contact_details">
                                        <span>+971 50 580 3883</span>
                                    </div>
                                    <div class="contact_heading">
                                        <span></span>
                                    </div>
                                    <div class="contact_details">
                                        <span class="pd_left">+971 2 674 3006</span>
                                    </div>
                                </div>
                                <div class="contact_details_content">
                                    <div class="contact_heading">
                                        <span>Email:</span>
                                    </div>
                                    <div class="contact_details">
                                        <span> info@alshifamedicalsupply.ae</span>
                                    </div>
                                    <div class="contact_heading">
                                        <span></span>
                                    </div>
                                    {/* <div class="contact_details">
                                        <span class="pd_left">support@gmail.com</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="google_map_wrapper">
                                <div id="map">
                                    <iframe
                                        title="AL SHIFA MEDICAL SUPPLIES LLC OPC"
                                        src="https://www.google.com/maps?q=24.495957473472455,54.372218700000005&hl=en&z=15&output=embed" 
                                        width="100%"
                                        height="100%"
                                        style={{ border: 0 }}
                                        allowfullscreen=""
                                        loading="lazy">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="call_to_action">
                <div class="container">
                    <div class="callout_wrap">
                        <div class="callout_content">
                            <h2>Get in touch with us now</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="comments_form_wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="formsix-pos">
                                <div class="form-group i-name">
                                    <label class="sr-only">Name</label>
                                    <input type="text" class="form-control" required="" id="name" placeholder="Full Name *" />
                                </div>
                            </div>
                            <div class="formsix-e">
                                <div class="form-group i-email">
                                    <label class="sr-only">Email Address</label>
                                    <input type="email" class="form-control" required="" id="email" placeholder="Email Address *" />
                                </div>
                            </div>
                            <div class="formsix-e">
                                <div class="form-group i-subject">
                                    <label class="sr-only">Subject</label>
                                    <input type="text" class="form-control" required="" id="subject" placeholder="Subject" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="formsix-m">
                                <div class="form-group i-message">
                                    <label class="sr-only">Message</label>
                                    <textarea class="form-control" required="" rows="7" id="message" placeholder="Message"></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary">Send Now !</button>
                </div>
            </div> */}

            {/* </div> */}

        </div>

    );
}

export default SectionOne;
