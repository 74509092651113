import React from "react";
import SectionOne from "./sections/section-one";
// import { Link } from 'react-router-dom';

const Home = () => {

    return (
        <div className="content">

            <div class="section_3" style={{ marginTop: '20px' }}>
                <div class="gradient_overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            {/* <div class="sub_title_section">
                                <ul class="sub_title">
                                    <li> <a href="#"> Welcome to </a> <i class="fa fa-angle-right" aria-hidden="true"></i> </li>
                                    <li>  AL Shifa Medical Supplies </li>
                                </ul>
                            </div> */}
                            <div class="page_header_title">
                                <h1>Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="page_header">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-xs-12 col-sm-6">
                            <h1>Services</h1>
                        </div>
                        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-6">
                            <div class="sub_title_section">
                                <ul class="sub_title">
                                    <Link to="/" className="home-page"><li> Home <i class="fa fa-angle-right" aria-hidden="true"></i> </li></Link>

                                    <li> <b>Services</b> <i aria-hidden="true"> </i> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <SectionOne />

        </div>
    );
}

export default Home;